<template>
  <div>
    <div v-if="loading" class="text-center">
      <b-spinner class="mt-3" style="width: 3rem; height: 3rem;" label="Loading..." />
    </div>
    <div v-else>
      <b-card
        v-if="Object.keys(all_history).length === 0"
        v-b-toggle.collapse-1
        border-variant="danger"
        header="This node has no history to show"
        class="text-primary"
        align="center"
        body-class="p-0"
        header-class="p-1"
      />
      <b-card
        v-else
        border-variant="info"
        align="center"
        body-class="p-0"
        header-class="p-1"
      >
        <!--    We need to separate the header so the whole card does not act as the toggle for the collapse    -->
        <template #header>
          <h6 v-b-toggle.collapse-1 class="mb-0 text-primary" style="width: 100%; text-align: left">
            {{ live_node.updated ? 'Current Data - Updated by ' + getUserUsername(live_node.updated_by) + ' on '+ formatDateTime(live_node.updated) : 'Current Data - Created by ' + getUserUsername(live_node.created_by) + ' on ' + formatDateTime(live_node.created) }}
          </h6>
        </template>
        <b-collapse id="collapse-1" class="mt-1 mb-0">
          <div
            v-for="(node_data, index) in live_node"
            :key="node_data.id"
            style="text-align: left; padding-left: 10px"
          >
            <div v-if="ignoreFields(index)">
              <b-card-text class="text-capitalize mb-0 text-primary">
                {{ index }}
              </b-card-text>
              <h5 v-if="calcColor(index, live_node)" style="color: green">
                <span v-if="index==='created_by' || index==='updated_by'">
                  {{ getUserUsername(node_data) }}
                </span>
                <span v-else>
                  {{ node_data }}
                </span>
              </h5>
              <h5 v-else>
                <span v-if="index==='created_by' || index==='updated_by'">
                  {{ getUserUsername(node_data) }}
                </span>
                <span v-else>
                  {{ node_data }}
                </span>
              </h5>
            </div>
          </div>
        </b-collapse>
        <b-card-text style="font-size: 12px">
          Click header to expand or close...
        </b-card-text>
      </b-card>
      <b-card
        v-for="history in all_history"
        :key="history.id"
        class="border-30"
        border-variant="secondary"
        align="center"
        body-class="p-0"
        header-class="p-1"
        style="border: 3px"
      >
        <!--    We need to separate the header so the whole card does not act as the toggle for the collapse    -->
        <template #header>
          <h6 v-b-toggle="'collapse-' + history.id" class="mb-0 text-primary" style="width: 100%; text-align: left">
            {{ history.updated ? 'Updated by ' + getUserUsername(history.updated_by) + ' on ' + formatDateTime(history.updated) : 'Created by ' + getUserUsername(history.created_by) + ' on ' + formatDateTime(history.created) }}
          </h6>
        </template>
        <b-collapse :id="'collapse-' + history.id" class="mt-1 mb-0">
          <div
            v-for="(history_data, h_index) in history"
            :key="history_data.id"
            style="text-align: left; padding-left: 10px"
          >
            <div v-if="ignoreFields(h_index)">
              <b-card-text class="text-capitalize mb-0 text-primary">
                {{ h_index }}
              </b-card-text>
              <h5 v-if="calcColor(h_index, history)" style="color: green">
                <span v-if="h_index==='created_by' || h_index==='updated_by'">
                  {{ getUserUsername(history_data) }}
                </span>
                <span v-else>
                  {{ history_data }}
                </span>
              </h5>
              <h5 v-else>
                <span v-if="h_index==='created_by' || h_index==='updated_by'">
                  {{ getUserUsername(history_data) }}
                </span>
                <span v-else>
                  {{ history_data }}
                </span>
              </h5>
            </div>
          </div>
        </b-collapse>
        <b-card-text style="font-size: 12px">
          Click header to expand or close...
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { formatDateTime } from '@core/utils/filter'
import store from '@/store'

export default {
  name: 'NodeHistory',

  data() {
    return {
      formatDateTime,
      all_history: [],
      live_node: {},
      node_types: [],
      loading: false,
    }
  },
  mounted() {
    this.loadHistory()
  },
  methods: {
    loadHistory() {
      this.loading = true
      store
        .dispatch('nodeHistory/getNodeHistory', this.$route.params.nodeId)
        .then(response => {
          this.live_node = this.$store.state.nodeHistory.selectedParentNode
          this.all_history = this.$store.state.nodeHistory.selectedNodeHistory
        })
        .finally(() => {
          this.loading = false
        })
    },
    calcColor(index, currentHistory) {
      let color = false
      if (currentHistory.changed && currentHistory.changed.modified) {
        Object.entries(currentHistory.changed.modified).forEach(entry => {
          const [key] = entry
          if (index === key) {
            color = true
          }
          return color
        })
      }
      return color
    },
    ignoreFields(index) {
      const ignoreList = ['id', 'original_id', 'updated', 'created', 'changed', 'deletable', 'spec_id', 'model', 'model_id']
      return !ignoreList.includes(index)
    },
  },
}
</script>

<style scoped>
h6 {
    font-size: 18px;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.object_text ::v-deep p {
  margin: 0;
}
</style>
